import { computed, Injectable, signal } from '@angular/core';
import Menus from '../../core/constants/sidebar-menu.constants';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  user = signal<User | null>(null);
  isLoggedIn = signal(false);
  menus = signal(Menus);
  currentMenus = computed(() => {
    if (this.isLoggedIn()) {
      return this.filterMenus();
    }
    return this.filterMenus();
  });
  userInfo = computed(() => {
    const user = this.user();
    if (user) {
      return user;
    }
    const token = localStorage.getItem('access_token');
    const payload = token ? JSON.parse(atob(token.split('.')[1])) : null;
    return {
      email: payload?.email,
      role: payload?.role,
      plan: payload?.plan
    };
  });

  private filterMenus() {
    return this.menus().filter(menu => {
      if (menu.requiredLogin && !this.isLoggedIn()) {
        return false;
      }
      if (menu.label === 'Login' && this.isLoggedIn()) {
        return false;
      }
      return true;
    });
  }
}

export interface User {
  email: string;
  role: string;
}

export interface ValidateSubscription {
  isActive: boolean;
  plan: string
}


